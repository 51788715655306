import EmployeeStatisticsEntityModule from '@/store/employees/statisticsEntity';
import { getAddressViaCoordinates } from '@/api/geo';
import ResponseHandlerModule from '@/store/modules/responseHandler';

export default class YandexMap {
  isBlock = false;
  zoom = 15.5;
  entity: any = {};
  placeMarkOnClick: any = null;
  shopsPlaceMark: any = {};
  circles: any = [];

  initMap(coordinates: { latitude: number; longitude: number }, domElementId: string, zoom?: number): void {
    if (!coordinates) {
      return;
    }

    this.entity = new (window as any).ymaps.Map(domElementId, {
      center: [coordinates.latitude, coordinates.longitude],
      zoom: zoom ? zoom : this.zoom,
      controls: ['zoomControl', 'fullscreenControl'],
    });
  }

  getCenterAndZoom(bounds: any) {
    return (window as any).ymaps.util.bounds.getCenterAndZoom(bounds, [370, 400]);
  }

  async addPlaceMarker(coordinates: { latitude: number; longitude: number }): Promise<void> {
    this.placeMarkOnClick = this.createPlaceMark([coordinates.latitude, coordinates.longitude]);
    this.entity.geoObjects.add(this.placeMarkOnClick);

    let address = '';
    try {
      address = await getAddressViaCoordinates(coordinates.latitude, coordinates.longitude);
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: 'Не удалось получить адрес по координатам',
        type: 'fail',
      });
    }

    this.setPlaceMarkAddress(address);
  }

  addEventsClick(): void {
    this.entity.events.add('click', async (e: { get: (value: string) => number[] }) => {
      const coordinates = e.get('coords');

      if (this.isBlock) {
        return;
      }

      if (this.placeMarkOnClick) {
        this.updatePlaceMarker(coordinates);
      } else {
        this.placeMarkOnClick = this.createPlaceMark(coordinates);
        this.entity.geoObjects.add(this.placeMarkOnClick);
      }

      let address = '';
      try {
        address = await getAddressViaCoordinates(coordinates[0], coordinates[1]);
      } catch (error) {
        ResponseHandlerModule.showNotify({
          message: 'Не удалось получить адрес по координатам',
          type: 'fail',
        });
      }
      this.setPlaceMarkAddress(address);
      document.body.dispatchEvent(new CustomEvent('yandexMapClick', { detail: coordinates }));
    });
  }

  updatePlaceMarker(coordinates: number[]): void {
    this.placeMarkOnClick.geometry.setCoordinates(coordinates);
  }

  createPlaceMark(coordinates: any) {
    return new (window as any).ymaps.Placemark(
      coordinates,
      {
        address: '',
      },
      {
        preset: 'islands#violetDotIcon',
        iconColor: '#6E258B',
        hintLayout: this.createHintLayout(),
      }
    );
  }

  createHintLayout() {
    return (window as any).ymaps.templateLayoutFactory.createClass(
      "<div class='cp-map-hint'>" + '{{ properties.address.value }}' + '<div>{{ properties.clientName }}</div>' + '</div>',
      {
        getShape: function () {
          const el = this.getElement();
          let result = null;
          if (el) {
            const firstChild = el.firstChild;
            result = new (window as any).ymaps.shape.Rectangle(
              new (window as any).ymaps.geometry.pixel.Rectangle([
                [0, 0],
                [firstChild.offsetWidth, firstChild.offsetHeight],
              ])
            );
          }

          return result;
        },
      }
    );
  }

  setPlaceMarkAddress(address: string): void {
    this.placeMarkOnClick.properties.set({
      address: { value: address },
    });
  }

  initShops(shops: any) {
    const that = this;
    let shop: any;
    for (shop of Object.values(shops)) {
      const shopCheckedValue = (EmployeeStatisticsEntityModule.model.shops as any)[shop.id]['isActive']['list'][0].checked;
      const presetValue = shopCheckedValue ? 'islands#violetStretchyIcon' : 'islands#grayStretchyIcon';

      this.shopsPlaceMark[shop.id] = new (window as any).ymaps.Placemark(
        [shop.addressCoordinates.latitude, shop.addressCoordinates.longitude],
        {
          iconContent: shop.code,
          address: shop.address,
          clientName: shop.clientName,
          shopId: shop.id,
        },
        {
          preset: presetValue,
          hintLayout: this.createHintLayout(),
        }
      );
      this.entity.geoObjects.add(this.shopsPlaceMark[shop.id]);

      this.shopsPlaceMark[shop.id].events.add('click', function (event: any) {
        const shopId = event.get('target').properties.get('shopId');

        const isCheckedNew = !(EmployeeStatisticsEntityModule.model.shops as any)[shopId]['isActive']['list'][0].checked;
        EmployeeStatisticsEntityModule.updateIsActiveStatus({
          id: shopId,
          bool: isCheckedNew,
        });

        that.updateShopPlaceMarkColor(shopId, isCheckedNew);
      });
    }
  }

  updateShopPlaceMarkColor(id: string, isChecked: boolean) {
    this.shopsPlaceMark[id].options.set({
      preset: isChecked ? 'islands#violetStretchyIcon' : 'islands#grayStretchyIcon',
    });
  }

  createCirclesByPoints(points: any, hideCenterCircle?: boolean) {
    let point: any;
    const circles: any = [];

    for (point of Object.values(points)) {
      const circleDistance = this.createCircleDistance(point);
      this.entity.geoObjects.add(circleDistance);

      circles.push(circleDistance);

      if (!hideCenterCircle) {
        const centerCircle = this.createCenterCircle(point.addressCoordinates);
        this.entity.geoObjects.add(centerCircle);
      }
    }

    this.circles = circles;
  }

  clearCirclesByPoints() {
    this.circles.forEach((point: any) => {
      this.entity.geoObjects.remove(point);
    });
  }

  createCircleDistance(point: any) {
    return new (window as any).ymaps.Circle(
      [[point.addressCoordinates.latitude, point.addressCoordinates.longitude], point.distance],
      {
        hintContent: point.address,
      },
      {
        fillColor: '#FFC42B40',
        strokeColor: '#FFC42B',
        strokeOpacity: 0.7,
        strokeWidth: 2,
      }
    );
  }

  createCenterCircle(coordinates: { latitude: string; longitude: string }) {
    return new (window as any).ymaps.Placemark(
      [coordinates.latitude, coordinates.longitude],
      {},
      {
        preset: 'islands#darkOrangeCircleDotIcon',
      }
    );
  }
}
