import { EmployeeStatisticModel } from '@/interfaces/models/employees.interface';
import MapEntity from '@/lib/formFactory/mapEntity';

export default class EntityModel {
  model: EmployeeStatisticModel;

  constructor() {
    const addressCoordinates = new MapEntity('addressCoordinates');
    addressCoordinates.setValue({
      latitude: 55.754871,
      longitude: 37.624113,
    });

    this.model = {
      points: {},
      shops: {},
      address: { id: null, value: '' },
      distance: '',
      addressCoordinates: addressCoordinates.entity[addressCoordinates.entityName],
    };
  }
}
