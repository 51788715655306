/**
 * Employees statistics entity store.
 *
 */

import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import EntityBaseModule from '@/store/entity';
import { EmployeeStatisticModel } from '@/interfaces/models/employees.interface';
import store from '@/store';
import EntityModel from '@/store/employees/statisticsEntityModel';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import EmployeeStatisticsModule from './statistics';

import { getAddressViaCoordinates, getCoordinates } from '@/api/geo';
import {
  getEmployeeShopsList,
  getEmployeePointsList,
  addNewStatisticPoint,
  saveStatisticPoint,
  updateMarketsManagement,
  deleteStatisticPoint,
} from '@/api/employees';

export const MODULE_NAME = 'employeeStatisticsEntity';
import { coordinates } from '@/lib/formFactory/map.interface';
import { strings } from '@/lib/stringConst';

export enum ShopStatus {
  Added = 'added',
  Banned = 'banned',
  Suitable = 'suitable',
}

export interface PointInterface {
  id: string;
  address: string;
  distance: number;
  name: string;
  addressCoordinates?: {
    latitude: number;
    longitude: number;
  };
}

export interface ShopInterface {
  id: string;
  code: string;
  name: string;
  client_id: string;
  client_name: string;
  address: string;
  status: ShopStatus;
  shift_count: number;
  addressCoordinates?: {
    latitude: number;
    longitude: number;
  };
}

export interface ShopListInterface {
  id: string;
  code: string;
  name: string;
  clientId: string;
  clientName: string;
  address: { id: string; value: string };
  status: string;
  shiftCount: number;
  addressCoordinates?: {
    latitude: number;
    longitude: number;
  };
  isActive: Record<string, string | Record<number, Record<string, string | boolean>>>;
}

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class EmployeeStatisticsEntityModule extends EntityBaseModule {
  model: EmployeeStatisticModel;
  pointsTitle = 'Точки';
  pointsTitleAdd = 'Новая точка';
  pointsTitleEdit = 'Редактировать';
  pointTitle = '';
  type = '';
  pointId = '';
  shopsMapParams = {
    center: {
      latitude: 55.754871,
      longitude: 37.624113,
    },
    zoom: 11,
  };
  selectedAddress = {};

  constructor(module: EmployeeStatisticsEntityModule) {
    super(module);

    const entityModel = new EntityModel();
    this.model = entityModel.model;
  }

  @Mutation
  SET_POINT_ID(id: string) {
    this.pointId = id;
  }

  @Mutation
  SET_POINTS(list: Record<string, PointInterface>) {
    this.model.points = list;
  }

  @Mutation
  SET_SHOPS(list: Record<string, ShopListInterface>) {
    this.model.shops = list;
  }

  @Mutation
  SET_SHOPS_MAP_CENTER(center: { latitude: number; longitude: number }) {
    this.shopsMapParams.center = center;
  }

  @Mutation
  UPDATE_IS_ACTIVE_STATUS(params: { id: string; bool: boolean }) {
    (this.model.shops[params.id]['isActive']['list'][0] as Record<string, boolean>).checked = params.bool;
  }

  @Mutation
  SET_TITLE(type: string) {
    this.pointTitle = type === 'add' ? this.pointsTitleAdd : this.pointsTitleEdit;
    this.type = type;
  }

  @Mutation
  SET_SELECTED_ADDRESS(address: { address: string; latitude: number; longitude: number }) {
    this.selectedAddress = address;
  }

  @Mutation
  UPDATE_POINT_ADDRESS(params: { id: number; address: string }) {
    if (this.model.points[params.id]) {
      this.model.points[params.id].address = params.address;
    }
  }

  @Action({ rawError: true })
  async initPoints(employeeId: string) {
    await this.getPointsListById(employeeId);
    await this.getShopsList(employeeId);
  }

  @Action({ rawError: true })
  async getPointsListById(employeeId: string) {
    try {
      const points = await getEmployeePointsList(employeeId);
      this.setPointsList(points);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setPointsList(points: Record<string, string[] | Record<string, string | number>[]>) {
    const pointsList: Record<string, PointInterface> = {};
    for (const row of Object.values(points.rows)) {
      pointsList[row.id] = {
        id: row.id,
        address: row.address,
        name: row.name || '',
        addressCoordinates: {
          latitude: row.lat,
          longitude: row.long,
        },
        distance: row.distance,
      };
    }

    this.context.commit('SET_POINTS', pointsList);
  }

  @Action({ rawError: true })
  async getShopsList(employeeId: string) {
    try {
      const shops = await getEmployeeShopsList(employeeId);
      this.setShops(shops);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setShops(shops: ShopInterface[]) {
    //TODO:: calculate center
    // zoom
    let sumLatitude = 0;
    let sumLongitude = 0;
    const shopsList: Record<string, ShopListInterface> = {};
    for (const shop of Object.values(shops)) {
      shopsList[shop.id] = {
        id: shop.id,
        code: shop.code,
        name: shop.name,
        clientId: shop.client_id,
        clientName: shop.client_name,
        address: { id: shop.address as string, value: shop.address as string },
        status: shop.status,
        addressCoordinates: {
          latitude: shop.addressCoordinates?.latitude as number,
          longitude: shop.addressCoordinates?.longitude as number,
        },
        isActive: {
          key: 'isActive',
          name: 'isActive',
          type: 'checkbox',
          list: {
            0: {
              id: shop.id,
              name: '',
              checked: [ShopStatus.Added, ShopStatus.Suitable].includes(shop.status),
            },
          },
        },
        shiftCount: shop.shift_count,
      };

      if (!shop.addressCoordinates) {
        continue;
      }
      sumLatitude += shop.addressCoordinates.latitude;
      sumLongitude += shop.addressCoordinates.longitude;
    }
    this.context.commit('SET_SHOPS', shopsList);

    if (sumLatitude !== 0) {
      const center = {
        latitude: sumLatitude / Object.values(shops).length,
        longitude: sumLongitude / Object.values(shops).length,
      };
      this.context.commit('SET_SHOPS_MAP_CENTER', center);
    }
  }

  @Action({ rawError: true })
  async initEditPoint(point: Record<string, string | number | Record<string, number>>) {
    await this.updateAddressCoordinates(point.addressCoordinates as { latitude: number; longitude: number });

    this.context.commit('SET_MODEL_VALUE', { key: 'distance', value: point.distance });
    this.context.commit('SET_MODEL_VALUE', { key: 'address', value: { id: point.address, value: point.address } });

    this.context.commit('SET_POINT_ID', point.id);
  }

  @Action({ rawError: true })
  async updatePointAddress(point: PointInterface) {
    if (point.addressCoordinates) {
      const address = await getAddressViaCoordinates(point.addressCoordinates.latitude, point.addressCoordinates.longitude);
      if (address) {
        this.context.commit('UPDATE_POINT_ADDRESS', { id: point.id, address });
      }
    }
  }

  @Action({ rawError: true })
  updateTitle(type: string) {
    this.context.commit('SET_TITLE', type);
  }

  @Action({ rawError: true })
  updateIsActiveStatus(params: { id: string; bool: boolean }) {
    this.context.commit('UPDATE_IS_ACTIVE_STATUS', params);
  }

  @Action({ rawError: true })
  async getCoordinates(value: string) {
    try {
      if (value.length === 0) {
        return;
      }

      const coordinates = await getCoordinates(value);
      this.updateAddressCoordinates({ latitude: coordinates.geo_lat, longitude: coordinates.geo_lon });

      return coordinates;
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.errors.fields, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  updateAddressCoordinates(coordinates: { latitude: number; longitude: number }) {
    this.context.commit('SET_ADDRESS_COORDINATES', coordinates);
  }

  @Action({ rawError: true })
  async createPoint(params: { employeeId: string; name: string; distance: number | string; address: string }) {
    try {
      const data = await this.preparePointData({ distance: params.distance, address: params.address, name: params.name });
      const result = await addNewStatisticPoint(params.employeeId, data);
      ResponseHandlerModule.showNotify({ message: 'Точка создана', type: 'ok' });

      return result;
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async updatePoint(params: { distance: number | string; address: string; name: string }) {
    try {
      const data = await this.preparePointData({ distance: params.distance, address: params.address, name: params.name });
      const result = await saveStatisticPoint(this.pointId, data);
      ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });

      return result;
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async deletePoint() {
    try {
      const result = await deleteStatisticPoint(this.pointId);
      ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });

      return result;
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  preparePointData(params: { distance: number | string; address: string; name?: string }) {
    return {
      name: params.name || '',
      address: params.address,
      distance: params.distance,
      lat: (this.model.addressCoordinates.value as coordinates).latitude,
      long: (this.model.addressCoordinates.value as coordinates).longitude,
    };
  }

  @Action({ rawError: true })
  async saveUpdate(employeeId: string) {
    try {
      const data = await this.prepareData();
      await updateMarketsManagement(employeeId, data);
      ResponseHandlerModule.showNotify({ message: 'Точки обновлены', type: 'ok' });
      await EmployeeStatisticsModule.getListById(employeeId);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  prepareData() {
    const data: Record<string, string> = {};
    for (const shop of Object.values(this.model.shops)) {
      if ((shop['isActive']['list'][0] as Record<string, boolean>).checked) {
        data[`readyMarkets[${shop.id}]`] = shop.id;
        continue;
      }

      data[`bannedMarkets[${shop.id}]`] = shop.id;
    }

    return data;
  }

  @Action({ rawError: true })
  resetModel() {
    this.context.commit('UPDATE_ADDRESS_HINTS', {});
    this.context.commit('SET_MODEL_VALUE', { key: 'distance', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'address', value: { id: null, value: '' } });
  }

  @Action({ rawError: true })
  updateSelectedAddress(coordinates: { latitude: number; longitude: number }) {
    getAddressViaCoordinates(coordinates.latitude, coordinates.longitude).then((addressString) => {
      if (addressString !== '') {
        this.context.commit('SET_SELECTED_ADDRESS', {
          address: addressString,
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
        });
      }
    });
  }
}
export default getModule(EmployeeStatisticsEntityModule);
