/**
 * Employees statistics.
 *
 */

import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { modalParams } from './statisticModalParams';

import { getEmployeeStatisticsList, deleteShopFavorite } from '@/api/employees';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import ModalsModule from '@/store/modals';
import EmployeeStatisticsEntityModule from './statisticsEntity';
export const MODULE_NAME = 'employeeStatistics';
import { PageSort } from '@/lib/layouts/page/page.interface';
import { TableInterface, TableRow } from '@/lib/layouts/page/table.interface';
import { strings } from '@/lib/stringConst';

export interface ModalParamsInterface {
  [index: string]: Record<string, string | boolean | null | Record<string, string>>;
  add: {
    isShow: boolean;
    title: string;
  };
  edit: {
    isShow: boolean;
    title: string;
  };
  delete: {
    id: null | string;
    shopId: null | string;
    name: string;
    text: string;
    handlers: {
      yes: string;
      no: string;
    };
  };
}

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class EmployeeStatisticsModule extends PageBaseModule {
  modalParams: ModalParamsInterface = modalParams;

  constructor(module: EmployeeStatisticsModule) {
    super(module);

    const page = new PageEntity();
    page.setTitle('');
    this.pageSettings = page.values;
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.employeesSort = JSON.stringify(sorts);
  }

  @Mutation
  TOGGLE_MODAL(payload: { type: string; bool: boolean }) {
    this.modalParams[payload.type].isShow = payload.bool;
  }

  @Mutation
  SET_DELETE_ID(id: string) {
    this.modalParams.delete.id = id;
  }

  @Mutation
  SET_DELETE_SHOP_ID(id: string) {
    this.modalParams.delete.shopId = id;
  }

  @Mutation
  SET_DELETE_TEXT(text: string) {
    this.modalParams.delete.text = text;
  }

  @Action({ rawError: true })
  async initListById(employeeId: string) {
    this.context.commit('SET_SORT', window.localStorage.employeesSort ? JSON.parse(window.localStorage.employeesSort) : {});

    await this.getListById(employeeId);
  }

  @Action({ rawError: true })
  async getListById(employeeId: string) {
    try {
      let sort = '';
      for (const [index, item] of Object.entries(this.pageSettings.sort)) {
        sort += `sort[${index}][id]=${item.key}&sort[${index}][value]=${item.sort}&`;
      }

      const result = await getEmployeeStatisticsList(employeeId, sort);
      await this.context.dispatch('setList', result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async sort(params: { field: string; sort: string; id: string }) {
    const sortProcessed = await this.context.dispatch('sortProcessed', params);
    await this.context.commit('SET_SORT', sortProcessed);
    await this.getListById(params.id);
  }

  @Action({ rawError: true })
  async clearTableSort(employeeId: string) {
    await this.context.commit('SET_SORT', {});
    await this.getListById(employeeId);
  }

  @Action({ rawError: true })
  showModal(type: string) {
    this.context.commit('TOGGLE_MODAL', { type: type, bool: true });
  }

  @Action({ rawError: true })
  hideModal(type: string) {
    EmployeeStatisticsEntityModule.resetModel();
    this.context.commit('TOGGLE_MODAL', { type: type, bool: false });
  }

  @Action({ rawError: true })
  deleteFromFavorite(params: { shopId: string; employeeId: string }) {
    const shop: { fullName: string } = (Object.values((this.pageSettings.table as TableInterface).rows).filter(
      (item: TableRow) => item.id === params.shopId
    ) as { fullName: string }[])[0];

    this.context.commit('SET_DELETE_ID', params.employeeId);
    this.context.commit('SET_DELETE_SHOP_ID', params.shopId);
    this.context.commit('SET_DELETE_TEXT', `Ты действительно хочешь удалить объект "${shop.fullName}" из доступных?`);
    ModalsModule.updateParams(this.modalParams.delete);
    ModalsModule.openModalByType('warning');
  }

  @Action({ rawError: true })
  async deleteById(params: { id: string }) {
    try {
      const result = await deleteShopFavorite(this.modalParams.delete.shopId as string, params.id);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Объект удалён из доступного списка', type: 'ok' });

        this.getListById(params.id);
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message, type: 'fail' });
    }
  }
}

export default getModule(EmployeeStatisticsModule);
